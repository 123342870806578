.landing {
    padding: 0 8vw 2rem;
    height: 90vh;
    display: flex;
    align-items: center;
    font-family: 'Neue Plak';
    color: #263238;
}


.landing__content {
    width: 50%;
}

.landing__content > h2 {
    margin-top: 1rem;
}

.landing__content > p {
    margin: 1rem 0 1.5rem 0;
    line-height: 160%;
    font-size: 1.1rem;
    font-family: 'Manrope';
}

.landing__content > a {
    font-family: 'NB International Mono';
    text-decoration: none;
    font-size: 0.8rem;
    color: #263238;
    background-color: #EEFF41;
    border: solid #263238 2px;
    padding: 1rem 3rem;
}

.landing__content > a:hover {
    cursor: pointer;
}

.landing > img {
    padding: 3rem 5rem 5rem 0;
    width: 45%;
}

@media screen and (max-width: 1024px) {
    .landing {
        flex-direction: column;
        height: auto;
        padding: 4rem 6vw;
    }
    .landing__content {
        width: 100%;
    }
    .landing__content > p {
        font-size: 0.9rem;
    }
    .landing > img {
        padding: 0 0 2rem 0;
    }
}

@media screen and (max-width: 670px) {
    .landing > img {
        width: 70%;
    }
}

@media screen and (max-width: 480px) {
    .landing > img {
        width: 100%;
    }
}