nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8vh 6vw 0vh 6vw;
}

nav img {
    height: 3rem;
}

nav > ul {
    display: flex;
    list-style: none;
    font-family: 'Manrope';
    align-items: center;
}

nav > ul > li {
    text-decoration: none;
    margin-left: 2.6rem;
}

nav > ul > li a {
    font-size: 1.2rem;
    text-decoration: none;
    color: #263238;
}

nav > ul > li p {
    font-size: 1.2rem;
    text-decoration: none;
    color: #263238;
}

.li {
    margin-left: 2.6rem;
    font-size: 1.2rem;
    text-decoration: none;
    color: #263238;
}

.active {
    text-decoration: underline #EEFF41;
    text-underline-offset: 0.2rem;
}

#contact-button {
    background-color: #263238;
    padding: 1rem;
    color: #fff;
    font-family: 'NB International Mono';
    font-size: 0.8rem;
}

@media screen and (max-width: 1024px) {
    nav {
        flex-direction: column;
        height: 8vh;
        padding-top: 3rem;
    }

    nav > img {
        height: 2rem;
    }

    nav > ul > li {
        margin-left: 2rem;
    }

    nav > ul > li a {
        font-size: 0.9rem;
        text-decoration: none;
        color: #313131;
    }

    nav > ul > li p {
        font-size: 0.9rem;
        text-decoration: none;
        color: #313131;
    }

    #contact-button {
        display: none;
    }
}

@media screen and (max-width: 500px) {
    nav > ul > li {
        margin-left: 1rem;
    }
    nav > ul > li a {
        font-size: 0.7rem;
    }
    nav > ul > li p {
        font-size: 0.7rem;
        transform: translateY(0.1rem);
    }
}