* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  width: 100%;
  background-color: white;
}

/********* FONTS ********/

@import url("https://fonts.googleapis.com/css2?family=Manrope&display=swap");
/* to use this, font-family: 'Manrope', sans-serif; */

@font-face {
  font-family: "Neue Plak";
  src: url("./assets/fonts/NeuePlak-ExtendedBold.woff") format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "NB International Mono";
  src: url("./assets/fonts/NBInternationalMonoWebfont.woff") format("woff");
}

/********* Register ********/
.main {
  position: relative;
  overflow-x: hidden;
}

.register {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6rem 8vw;
  background-color: white;
  color: black;
}

.register-left {
  margin-right: 3rem;
}

.register-left a {
  text-decoration: none;
}

.register h1 {
  font-size: 2rem;
  font-family: "Neue Plak";
  font-weight: normal;
  letter-spacing: 0.04rem;
  margin-bottom: 1.25rem;
  line-height: 120%;
}

.register p {
  font-size: 1.1rem;
  font-family: "Manrope";
  letter-spacing: 0.02rem;
  line-height: 160%;
  margin-bottom: 1.25rem;
}

.register-graphic {
  height: 320px;
  width: 800px;
  object-fit: contain;
}

.button-yellow {
  display: flex;
  background-color: #EEFF41;
  height: 45px;
  width: 225px;
  cursor: pointer;
  margin-bottom: 2rem;
  border: 2px solid black;
}

.button-yellow p {
  display: flex;
  margin: auto;
  font-size: 0.8rem;
  font-family: "NB International Mono";
  color: black;
  text-transform: uppercase;
}

/********* Register Media Query ********/
@media (max-width: 980px) {
  .register {
    flex-direction: column-reverse;
  }
  .register-left {
    margin-right: 0;
  }
  .register-graphic {
    margin-bottom: 2rem;
  }
}

@media (max-width: 600px) {
  .register-graphic {
    width: 100%; 
    height: 100%;
    order: 1;
    margin-top: 2rem;
    margin-bottom: 0;
  }

  .register-left {
    order: 2;
  }

  .register h1 {
    font-size: 1.75rem;
  }

  .register p {
    font-size: 0.9rem;
  }

  .register {
    padding: 4rem 6vw;
    flex-direction: column;
  }
  .button-yellow {
    display: flex;
    background-color: #EEFF41;
    height: 40px;
    width: 175px;
    cursor: pointer;
    margin-bottom: 2rem;
    border: 2px solid black;
  }
  .button-yellow p {
    font-size: 0.76rem;
  }
}

/********* Attending ********/
.attending {
  display: flex;
  flex-direction: column;
  padding: 6rem 5vw;
  background-color: #263238;
  color: white;
}

.attending p {
  font-size: 1.4rem;
  font-family: 'Neue Plak';
  text-align: center;
}

.attending h1 {
  font-size: 2rem;
  font-family: "Neue Plak";
  font-weight: normal;
  letter-spacing: 0.04rem;
  margin: 0 3vw 1.25rem 3vw;
  line-height: 120%;
}

.attending-card {
  display: flex;
  justify-content: flex-end;
  background-color: white;
  margin: 3vw;
  cursor: pointer;
}

.attending-image {
  display: flex;
  margin: 2rem auto;
  height: 100px;
  width: 200px;
  object-fit: contain;
}

.cross-image {
  display: flex;
  position: absolute;
  height: 12px;
  width: 12px;
  transform: rotate(135deg) translateX(2rem);
}

@media (max-width: 768px) {
  .attending {
    padding: 4rem 6vw;
  }

  .attending h1 {
    font-size: 1.75rem;
  }
}

/********* Attending Modal ********/
.m-background {
  position: fixed;
  background-color: black;
  opacity: 0.5 !important;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
}

.m-card {
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: white;
  height: 80vh;
  width: 80vw;
  top: 10vh;
  left: 10vw;
}

.m-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #263238;
  padding: 1.25rem 2.5rem;
}

.company-title {
  margin-top: 2rem;
}

.m-top h2 {
  font-size: 1.5rem;
  font-family: "Neue Plak";
  color: white;
  font-weight: normal;
}

.m-bottom {
  display: flex;
  flex-direction: row;
  margin: 1.5rem 2.5rem 2.5rem;
}

.m-left {
  width: 45vw;
  margin-right: 4rem;
}

.m-left p {
  font-size: 1.1rem;
  font-family: "Manrope";
  color: #424242;
  line-height: 170%;
  letter-spacing: 0.02rem;
  text-align: left;
}

.m-x {
  height: 22px;
  width: 22px;
  cursor: pointer;
}

.m-right {
  display: flex;
  flex-direction: column;
}

.m-right h3 {
  font-size: 1.1rem;
  font-family: "Manrope";
  color: #424242;
  font-weight: bold;
  letter-spacing: 0.02rem;
  line-height: 170%;
  margin-bottom: 0.25rem;
}

.m-right p {
  font-size: 1rem;
  font-family: "Manrope";
  color: #424242;
  line-height: 170%;
  letter-spacing: 0.02rem;
  text-align: left;
  text-decoration: underline #424242;
}

.m-margin {
  margin-top: 1rem;
}

/********* Modal Media Query ********/
@media (max-width: 800px) {
  .m-bottom {
    flex-direction: column;
  }
  .m-left {
    width: 100%;
    margin: 0 0 2rem 0;
  }
  .m-card {
    height: 95vh;
    top: 2.5vh;
    overflow-y: scroll;
  }
}

@media (max-width: 980px) {
  .m-card {
    width: 90vw;
    left: 5vw;
  }
}

/********* Contact ********/
.contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6rem 8vw;
  background-color: #263238;
  color: white;
}

.contact-left {
  margin-right: 3rem;
}

.contact h1 {
  font-size: 2rem;
  font-family: "Neue Plak";
  font-weight: normal;
  letter-spacing: 0.04rem;
  margin-bottom: 1.25rem;
  line-height: 120%;
}

.contact p {
  font-size: 1rem;
  font-family: "Manrope";
  letter-spacing: 0.02rem;
  line-height: 170%;
  margin-bottom: 1.25rem;
}

.steps-graphic {
  height: 150px;
  width: 550px;
  object-fit: contain;
}

.button-blue {
  display: flex;
  background-color: #BBDEFB;
  height: 45px;
  width: 225px;
  cursor: pointer;
  margin-bottom: 2rem;
}

.button-blue p {
  display: flex;
  margin: auto;
  font-size: 0.8rem;
  font-family: "NB International Mono";
  color: black;
  text-transform: uppercase;
}

/********* Contact Media Query ********/
@media (max-width: 980px) {
  .contact {
    flex-direction: column;
  }
  .contact-left {
    margin-right: 0;
  }
}

@media (max-width: 600px) {
  .steps-graphic {
    width: 100%; 
    height: 100%;
  }
}
/********* ABOUT ********/
.about-section1, .about-section2, .about-section3 {
  padding: 5rem 10vw;
}

.about-header {
  margin-bottom: 2rem !important;
}

.big-photo {
  display: block;
  width: 30vw;
}

.small-photo {
  height: 12vw;
  margin-bottom: 1.5rem;
}

.about h1 {
  font-family: "Neue Plak";
  font-size: 2rem;
  margin-bottom: 2rem;
}

.about h2 {
  font-family: "Neue Plak";
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.about p {
  font-family: "Manrope";
  font-size: 1.125rem;
  line-height: 170%;
}

.about-section1 p {
  margin-bottom: 2rem;
}

.about-section2 {
  background-color: #263238;
  color: #fff;
}

.about-section2 h2 {
  margin-bottom: 2.5rem;
}

.qa {
  margin-bottom: 1.25rem;
}

.about a {
  color: #fff;
}

.about-section3 {
  text-align: center;
}

.big-photo-mobile {
  display: none;
}

@media (max-width: 768px) {
  .about-section1, .about-section2, .about-section3 {
    padding: 4rem 6vw;
  }

  .big-photo {
    display: none;
  }

  .big-photo-mobile {
    display: block;
    width: 80vw;
  }

  .small-photo {
    height: 25vw;
    margin-bottom: 1rem;
  }

  .about h1, h1 {
    font-size: 1.75rem;
  }

  .about h2, h2 {
    font-size: 1.25rem;
  }

  .about p {
    font-size: 0.9rem;
  }

  .about-section3 h2 {
    margin-bottom: 3rem;
  }
}

/********* Animation ********/
.tape {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem 0;
  background-color: #EA80FC;
  width: 100vw;
  overflow-x: hidden;
}

.tape p {
  font-size: 1.1rem;
  font-family: "NB International Mono";
  color: black;
  position: absolute;
  text-transform: uppercase;
  letter-spacing: 2%;
  white-space: nowrap;
}