.sponsors {
    background-color: #263238;
    padding: 6rem 8vw;
    color: #fff;
}

.sponsors__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.sponsors__header > h1 {
    font-size: 2rem;
    font-family: 'Neue Plak';
    margin-bottom: 2rem;
}

.sponsors__header > a {
    font-size: 1.4rem;
    font-family: 'Neue Plak';
    margin-bottom: 2rem;
    text-decoration: none;
    color: #fff;
}

.sponsors > p {
    font-size: 1.4rem;
    font-family: 'Neue Plak';
    text-align: center;
}

.sponsors__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 2rem;
}

.sponsors .attending-card {
    margin: 0 1.2rem 1.2rem 0;
}

.sponsors .attending-image {
    margin: 2rem 7%;
    width: 86%;
}

.sponsors__list > .attending-card > img {
    width: 80%;
}

@media screen and (max-width: 1024px) {
    .sponsors {
        padding: 4rem 6vw;
    }
    .sponsors__header {
        flex-direction: column;
        align-items: flex-start;
    }
    .sponsors__header > h1 {
        margin-bottom: 1rem;
    }
    .sponsors__list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 4rem;
    }
    .sponsors .attending__card {
        margin: 0;
    }
}