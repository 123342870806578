footer {
    padding: 2rem 6vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Manrope';
    font-size: 1.1rem;
    color: #263238;
}

footer a {
    color: #263238;
}

@media screen and (max-width: 1024px) {
    footer {
        font-size: 0.9rem;
        flex-direction: column;
    }

    footer > a {
        margin-top: 0.6rem;
    }
}